import { DateTime } from "luxon";
// require('../utilities/DATE_UTILITIES')

export const HEADER_TYPES = {
    currency: (value) => Intl.NumberFormat("es-MX", {style: 'currency', currency: 'MXN'}).format(value),
    upper: (value) => (value ?? "").toUpperCase(),
    decimal: (value) => (Math.round(parseFloat(value) * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    percentage: (value) => parseFloat(value).toFixed(2)+"%",
    date: (value) => DateTime.fromISO(value).format("dd/LL/yyyy"),
    year: (value) =>  DateTime.fromISO(value).format("yyyy"),
    month: (value) => DateTime.fromISO(value).toFormat("LL"),
    day: (value) => DateTime.fromISO(value).toFormat("dd"),
    "date-time": (value) => DateTime.fromISO(value).toFormat("dd/LL/yyyy hh:mm a"),
    time: (value) => DateTime.fromISO(value).format("hh:mm:ss a"),
    boolean: (value) => value ? 'SI' : 'NO',
    link: (value) => "<a href=''>" + value + "<a>"
  }